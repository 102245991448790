
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Progress, Row, Col, Image, Dialog, Toast } from "vant";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    [Progress.name]: Progress,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Dialog.Component.name]: Dialog.Component,
  },
})
export default class Member extends Mixins(Mixin) {
  memberInfos: member.MemberInfo = {};
  fit = "member";

  //修复苹果手机背景图片加载不出来的问题
  get gradeEntrancePictureSrc(): string {
    let UserModule = this.UserModule;
    if (UserModule.isLogin) {
      return (
        UserModule.memberbackground ||
        "https://cdn.polyt.cn/assets/mini_app/background/membership.png"
      );
    }
    return "https://cdn.polyt.cn/assets/mini_app/background/membership.png";
    // let gradeEntrancePicture = this.memberInfos.gradeEntrancePicture;
    // return (
    //   gradeEntrancePicture || require("@/assets/images/Mine/小-未登录.png")
    // );
  }

  get describe(): string {
    let memberLevelInfo = this.memberInfos.memberLevelInfo;
    return memberLevelInfo ? memberLevelInfo.describe || "" : "";
  }

  get grouthNumber(): number {
    let memberInfos = this.memberInfos;
    let growthValue = memberInfos.growthValue;
    if (!growthValue) {
      return 0;
    }
    let nextLevelValue = memberInfos.nextLevelValue;
    if (!nextLevelValue) {
      return 0;
    }
    return (growthValue / nextLevelValue) * 10;
  }

  activated(): void {
    if (this.UserModule.isLogin) {
      this.InterfaceCacheModule.getData({
        cacheKey: "cuUser.memberInfo",
        apiMethod: this.$api.memberApi.cuUser.memberInfo,
        callback: ({ data }) => {
          this.memberInfos = data;
          // 由于用户登录时，返回信息不准，此处在首页加载时进行操作，拿的信息是实时准确的，
          // 将用户头像缓存到vuex中，供个人中心首页使用
          if (data.headImg) {
            this.UserModule.IMG_CHANGE_PERSIST(data.headImg);
          }
          // 由于用户登录时，返回信息不准，此处在首页加载时进行操作，拿的信息是实时准确的，
          // 将用户昵称缓存到vuex中，供个人中心首页使用
          if (data.userNikeName) {
            this.UserModule.NICKNAME_CHANGE(data.userNikeName);
          }
          // 由于用户登录时，返回信息不准，此处在首页加载时进行操作，拿的信息是实时准确的，
          // 将用户昵称缓存到vuex中，供个人中心首页使用
          if (data.gradeEntrancePicture) {
            this.UserModule.MEMBER_BG(data.gradeEntrancePicture);
          }
        },
      });
    } else {
      this.resetData();
    }
  }

  resetData(): void {
    this.memberInfos = {};
    this.fit = "member";
  }

  goMembershipDeatil(): void {
    if (!this.UserModule.isLogin) {
      this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
        router: this.$router,
        newPage: true,
        goBackAfterLogin: true,
      });
      return;
    }
    monitorEvent("MinePage_ClickVIPOffer", "点击会员权益"); // 埋点：我的，点击会员权益
    this.$router.push("/membership");
  }

  goMembershipGrowth(): void {
    // this.$toast("waitting");
    Dialog.confirm({
      confirmButtonText: "下载App",
      title: "想快速提升会员等级？",
      message: `<div>
      <span>
      下载保利票务APP，完成升级任务，还有额外权益可享哦~
      </span>
        </div>
      `,
    })
      .then(() => {
        monitorEvent("MinePage_ClickVIPUpgrade", "点击升级攻略"); // 埋点：我的，点击升级攻略
        this.$router.push("/mine/download-app");

        // on confirm
      })
      .catch(() => {
        // on cancel
      });
  }
}
